import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { MDBContainer } from 'mdbreact';

import './index.css';
import Auth from './containers/Auth/Auth';
import ModalsManager from './containers/ModalsManager/ModalsManager';
import SingleModal from './containers/SingleModal/SingleModal';
import ModalsContext from './context/modals-context';

import axios from 'axios';
import * as actions from './store/actions/index';
import { sanitizeInputValue, updateObject } from './shared/utility';

// axios.defaults.baseURL =
//   process.env.NODE_ENV === 'development'
//     ? 'https://modal-api.growpoland.usermd.net/modal-api'
//     : 'https://modal-api-grupazprmedia.growpoland.usermd.net/modal-api';

// GROW: https://growtest-modal-api.growpoland.pl/modal-manager

//https://modal-mgr.growpoland.usermd.net/modal-manager

axios.defaults.baseURL = "https://modal-mgr.growpoland.pl/modal-manager";

class App extends Component {
  state = {
    count: 10,
    page: 1,
    totalSize: 0,
    order: '',
    sort: '',
    search: {},
    searchControls: {
      name: '',
      instance: '',
      type: '',
      createdAtFrom: '',
      createdAtTo: '',
      active: '',
      isPopup: '',
    },
    modals: [],
    columns: {
      name: { label: 'Name', isSortable: true, order: '' },
      instance: { label: 'Website', isSortable: true, order: '' },
      active: { label: 'Status', isSortable: true, order: '' },
      type: { label: 'Type', isSortable: true, order: '' },
      createdAt: { label: 'Creation Date', isSortable: true, order: '' },
      updatedAt: { label: 'Last Modified', isSortable: true, order: '' },
      actions: { label: 'Actions', isSortable: false, order: '' },
    },
  };

  componentDidMount() {
    this.props.onTryAutoSignup();

    // this.props.isAuthenticated && this.fetchAllModals();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isAuthenticated && prevState.modals === this.state.modals) {
      this.getAllModals();
    }
  }

  getParamsToFetchAllModals = () => {
    const params = {
      count: this.state.count,
      page: this.state.page,
    };

    if (this.state.sort && this.state.order) {
      params.sort = this.state.sort;
      params.order = this.state.order;
    }

    const isQuery = Object.keys(this.state.search).length;

    if (isQuery) {
      params.search = this.state.search;
    }

    return params;
  };

  getAllModals = async () => {
    try {
      const response = await this.fetchAllModals();

      const modalsFromApi = response.modals;

      const nextModals = this.transformResModals({
        modals: modalsFromApi,
      });

      this.setState({
        modals: nextModals,
        totalSize: response.totalSize,
      });
    } catch (error) {}
  };

  fetchAllModals = async () => {
    const params = this.getParamsToFetchAllModals();

    const defResponse = { modals: [], totalSize: 0 };

    try {
      const res = await axios.get('/modals-mgr', {
        params,
        headers: {
          auth: this.props.token,
        },
      });

      defResponse.modals = res.data.elements;
      defResponse.totalSize = res.data.totalSize;

      return defResponse;
    } catch (error) {
      return defResponse;
      // temp solution, maybe better to render component with problems
    }
  };

  transformResModals = ({ modals }) => {
    return modals.map(modal => {
      return {
        id: modal.id,
        name: modal.name,
        instance: modal.instance,
        type: modal.type,
        createdAt: modal.createdAt,
        updatedAt: modal.updatedAt,
        active: modal.active,
        isPopup: modal.isPopup
      };
    });
  };

  updateModalsListWithCurrent = ({ id, name, instance, type, active }) => {
    let isNewModal = false;
    const updatedModals = this.state.modals.map(modal => {
      if (modal.id === id) {
        isNewModal = true;
        return { ...modal, id, name, instance, type, active };
      }
      return { ...modal };
    });

    if (isNewModal) {
      updatedModals.push({ id, name, instance, type, active });
    }

    this.setState({ modals: updatedModals });
  };

  sortHandler = ({ sortBy, prevOrder }) => {
    // disabled sort value is ''
    const nextOrder =
      prevOrder === '' ? 'asc' : prevOrder === 'asc' ? 'desc' : '';

    const oldColumns = { ...this.state.columns };
    const nextColumns = {};

    // reset all columns order with default '' and set cuurent order to new
    for (let key in oldColumns) {
      const oldColumn = oldColumns[key];

      const order = key === sortBy ? nextOrder : '';

      const columnUpdated = updateObject({
        oldObject: oldColumn,
        updatedProperties: { order: order },
      });

      nextColumns[key] = columnUpdated;
    }

    this.setState({ order: nextOrder, sort: sortBy, columns: nextColumns });
  };

  inputChangeHandler = ({ inputName, value }) => {
    const nextValue = sanitizeInputValue({ value });

    const nextSearchControls = updateObject({
      oldObject: this.state.searchControls,
      updatedProperties: { [inputName]: nextValue },
    });

    const nextSearch = this.getSearchQuery({
      searchValues: nextSearchControls,
    });

    this.setState({ searchControls: nextSearchControls, search: nextSearch });
  };

  getSearchQuery = ({ searchValues }) => {
    const nextSearchControls = { ...searchValues };
    const searchQuery = {};

    for (let key in nextSearchControls) {
      const isValidVal = nextSearchControls[key] !== '';

      if (isValidVal) {
        searchQuery[key] = this.addPartialWordSearch({
          name: key,
          value: nextSearchControls[key],
        });
      }
    }

    const isQuery = Object.keys(searchQuery).length;

    if (isQuery) {
      return btoa(JSON.stringify(searchQuery));
    }

    return searchQuery;
  };

  // Eloqua supports searching with '*' to search by parts of a word, add it when needed.
  addPartialWordSearch = ({ name, value }) => {
    const isPartialAllowed = name === 'instance' || name === 'name';

    if (isPartialAllowed) {
      return `*${value}*`;
    }

    return value;
  };

  deleteModalHandler = ({ id, name }) => {
    if (window.confirm(`Do you really want to delete modal ${name}?`)) {
      this.deleteModalRequest({ id })
        .then(() => {
          // fetching modals depends on state change, to update list view, you can change state.page, it's lazy
          this.setState(prevState => {
            const areModals = prevState.modals.length - 1 > 0;
            const currentPage = prevState.page;
            const prevPage = currentPage - 1;
            const FIRST_PAGE = 1;
            const isPrevPage = prevPage >= FIRST_PAGE;

            const newPage = areModals
              ? currentPage
              : isPrevPage
              ? prevPage
              : FIRST_PAGE;

            return { page: newPage };
          });
        })
        .catch(() => {
          window.alert(`Couldn't delete modal, please try again`);
        });
    }
  };

  deleteModalRequest = ({ id }) => {
    return axios.delete(`/modals-mgr/${id}`, {
      headers: {
        auth: this.props.token,
      },
    });
  };

  deleteFromModalsList = ({ id }) => {
    const clonedModals = this.state.modals.map(modal => {
      return { ...modal };
    });

    return clonedModals.filter(modal => {
      return modal.id !== id;
    });
  };

  entriesHandler = value => {
    let nextCount = sanitizeInputValue({ value });

    if (!nextCount) {
      nextCount = 10;
    }

    this.setState({ count: nextCount });
  };

  paginationHandler = toPage => {
    window.scrollTo(0, 0);
    this.setState({ page: toPage });
  };

  render() {
    return (
      <>
        <MDBContainer>
          <ModalsContext.Provider
            value={{ updateList: this.updateModalsListWithCurrent }}
          >
            {this.props.isAuthenticated ? (
              <Switch>
                {/* <Route path="/auth" component={Auth} /> */}
                <Route
                  path="/modals"
                  exact
                  render={props => {
                    return (
                      <ModalsManager
                        {...props}
                        addModal={this.addModal}
                        getAllModals={this.getAllModals}
                        modals={this.state.modals}
                        editHandler={this.editModal}
                        deleteHandler={this.deleteModalHandler}
                        sortHandler={this.sortHandler}
                        columns={this.state.columns}
                        sort={this.state.sort}
                        order={this.state.order}
                        change={this.inputChangeHandler}
                        searchControls={this.state.searchControls}
                        entriesHandler={this.entriesHandler}
                        count={this.state.count}
                        page={this.state.page}
                        totalSize={this.state.totalSize}
                        paginationHandler={this.paginationHandler}
                      />
                    );
                  }}
                />
                <Route path="/modals/:id" component={SingleModal} />
                <Redirect to="/modals" />
              </Switch>
            ) : (
              <Switch>
                <Route path="/auth" component={Auth} />
                <Redirect to="/auth" />
              </Switch>
            )}
          </ModalsContext.Provider>
        </MDBContainer>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token !== null,
    token: state.auth.token,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
