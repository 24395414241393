import * as actionTypes from './actionTypes';

import axios from 'axios';

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authSuccess = ({ token, instance }) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    token,
    instance
  };
};

export const authFail = error => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error,
  };
};

export const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('expirationDate');
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const checkAuthTimeout = ({ logoutDelay = 0 }) => {
  return dispatch => {
    setTimeout(() => {
      dispatch(logout());
    }, Math.max(logoutDelay, 0));
  };
};

export const auth = ({ company, username, password }) => {
  return dispatch => {
    dispatch(authStart());
    axios
      .post('/login', {
        company,
        username,
        password,
      })
      .then(response => {
        const data = response.data;

        let logoutDelay =
          new Date(data.tokenExpirationTime).getTime() - new Date().getTime();

        localStorage.setItem('token', response.data.tokenValue);
        localStorage.setItem('expirationDate', data.tokenExpirationTime);
        localStorage.setItem('instance', company);

        dispatch(authSuccess({ token: data.tokenValue, instance: company }));
        dispatch(checkAuthTimeout({ logoutDelay: logoutDelay }));
      })
      .catch(err => {
        dispatch(authFail(err.response));
      });
  };
};

export const authCheckState = () => {
  return dispatch => {
    const token = localStorage.getItem('token');
    const instance = localStorage.getItem('instance');
    if (!token) {
      dispatch(logout());
    } else {
      const expirationDate = new Date(localStorage.getItem('expirationDate'));
      if (expirationDate <= new Date()) {
        dispatch(logout());
      } else {
        dispatch(authSuccess({ token: token, instance: instance }));
        dispatch(
          checkAuthTimeout({
            logoutDelay: expirationDate.getTime() - new Date().getTime(),
          })
        );
      }
    }
  };
};
