import React, { Component } from 'react';
import { connect } from 'react-redux';

import ModalForm from '../../components/ModalForm/ModalForm';
import ModalsContext from '../../context/modals-context';
import ModalPreview from '../../components/ModalPreview/ModalPreview';

import axios from 'axios';
import { sanitizeInputValue, updateObject } from '../../shared/utility';

class SingleModal extends Component {
  state = {
    allControlsValid: false,
    isNewModal: this.props.match.params.id === 'new',
    currentModalControls: {
      active: {
        value: false,
        label: 'Active *',
        type: 'select',
        validation: { required: true },
        valid: true,
        touched: false,
        marked: false,
        group: 'general',
        options: [
          {
            text: 'yes',
            value: true,
          },
          {
            text: 'no',
            value: false,
            checked: true,
          },
        ],
      },
      name: {
        value: '',
        label: 'Name *',
        validation: { required: true },
        valid: false,
        touched: false,
        marked: false,
        group: 'general',
      },
      instance: {
        value: '',
        label: 'Website *',
        validation: { required: true },
        valid: false,
        touched: false,
        marked: false,
        group: 'general',
      },
      pathRules: {
        value: '',
        label: 'Path Rules',
        type: 'textarea',
        valid: true,
        touched: false,
        group: 'general',
      },

      landingPageURL: {
        value: '',
        label: 'URL Desktop *',
        valid: false,
        touched: false,
        group: 'content',
      },
      landingPageMobileURL: {
        value: '',
        label: 'URL Mobile *',
        valid: false,
        touched: false,
        group: 'content',
      },
      btnColor: {
        value: '',
        // value: '#333',
        label: 'Closure Button Color',
        valid: true,
        touched: false,
        group: 'content',
        hide: true
      },
      height: {
        value: '',
        label: 'Height Desktop (px, %)',
        validation: { isCSSsize: true },
        valid: true,
        touched: false,
        group: 'content',
        feedback: `Use only "px", "%"`,
      },
      width: {
        value: '',
        label: 'Width Desktop (px, %)',
        validation: { isCSSsize: true },
        valid: true,
        touched: false,
        group: 'content',
        feedback: `Use only "px", "%"`,
      },
      heightMobile: {
        value: '',
        label: 'Height Mobile (px, %)',
        validation: { isCSSsize: true },
        valid: true,
        touched: false,
        group: 'content',
        feedback: `Use only "px", "%"`,
      },
      widthMobile: {
        value: '',
        label: 'Width Mobile (px, %)',
        validation: { isCSSsize: true },
        valid: true,
        touched: false,
        group: 'content',
        feedback: `Use only "px", "%"`,
      },
      isPopup: {
        value: true,
        label: 'isPopup',
        type: 'select',
        validation: { required: false },
        valid: true,
        touched: false,
        marked: false,
        group: 'displayRules',
        options: [
          {
            text: 'yes',
            value: true,
            checked: true
          },
          {
            text: 'no',
            value: false
          },
        ],
      },
      displayType: {
        value: 'always',
        label: 'Display Type *',
        type: 'select',
        valid: true,
        touched: false,
        group: 'displayRules',
        hide: true,
        dependentControls: [
          {
            whenValue: 'range',
            name: 'displayUnit',
            validationRule: 'required',
          },
          {
            whenValue: 'range',
            name: 'displayFrom',
            validationRule: 'required',
          },
          {
            whenValue: 'range',
            name: 'displayTo',
            validationRule: 'required',
          },
          {
            whenValue: 'interval',
            name: 'displayUnit',
            validationRule: 'required',
          },
          {
            whenValue: 'interval',
            name: 'displayEach',
            validationRule: 'required',
          },
        ],
        options: [
          {
            text: 'always',
            value: 'always',
            checked: true,
          },
          {
            text: 'range',
            value: 'range',
          },
          {
            text: 'interval',
            value: 'interval',
          },
        ],
      },
      type: {
        value: '',
        label: 'Type *',
        type: 'select',
        valid: true,
        touched: false,
        marked: false,
        group: 'displayRules',
        hide: true,
        dependentControls: [
          {
            whenValue: 'onScroll',
            name: 'scrollAmount',
            validationRule: 'required',
          },
        ],
        options: [
          {
            text: 'onStart',
            value: 'onStart',
            disabled: false,
            icon: null,
            checked: true
          },
          {
            text: 'onExit',
            value: 'onExit',
            disabled: false,
            icon: null,
          },
          {
            text: 'onScroll',
            value: 'onScroll',
            disabled: false,
            icon: null,
          },
        ],
      },
      displayEach: {
        value: '',
        label: 'Display Each',
        validation: { isNumber: true },
        valid: true,
        touched: false,
        group: 'displayRules',
        hide: true
      },
      displayFrom: {
        value: '',
        label: 'Display From',
        validation: { isNumber: true },
        valid: true,
        touched: false,
        group: 'displayRules',
        hide: true
      },
      displayTo: {
        value: '',
        label: 'Display To',
        validation: { isNumber: true },
        valid: true,
        touched: false,
        group: 'displayRules',
        hide: true
      },
      displayUnit: {
        value: '',
        label: 'Display Unit',
        type: 'select',
        valid: true,
        touched: false,
        group: 'displayRules',
        hide: true,
        options: [
          {
            text: 'visits',
            value: 'visits',
          },
          {
            text: 'sessions (3,5h)',
            value: 'sessions',
          },
        ],
      },
      loadDelay: {
        value: '',
        label: 'Load Delay (seconds)',
        validation: { isNumber: true },
        valid: true,
        touched: false,
        group: 'displayRules',
        hide: true
      },
      scrollAmount: {
        value: '',
        label: 'Scroll Amount',
        validation: { isNumber: true },
        valid: true,
        touched: false,
        group: 'displayRules',
        hide: true
      },
      viewsPerUser: {
        value: '',
        label: 'Views Per User',
        validation: { isNumber: true },
        valid: true,
        touched: false,
        group: 'displayRules',
        hide: true
      },
      showAgainAfter: {
        value: '',
        label: 'Show Again After (minutes)',
        validation: { isNumber: true },
        valid: true,
        touched: false,
        group: 'displayRules',
        hide: true
      },
      priority: {
        value: 1,
        label: 'Priority (from 1 to 99)',
        validation: { isPriority: true },
        valid: true,
        touched: false,
        group: 'displayRules',
      },
      showTo: {
        value: 'all',
        label: 'Show to *',
        type: 'select',
        validation: { required: true },
        valid: true,
        touched: false,
        marked: false,
        group: 'audience',
        options: [
          {
            text: 'all',
            value: 'all',
            checked: true,
          },
          {
            text: 'anonymous',
            value: 'anonymous',
          },
          {
            text: 'contacts',
            value: 'contacts',
          },
        ],
      },
      segmentId: {
        value: '',
        label: 'Segment ID',
        validation: { isNumber: true },
        valid: true,
        touched: false,
        group: 'audience',
      },
    },
    isPreview: false,
    previewMode: 'desktop',
  };

  static contextType = ModalsContext;

  fetchModal = ({ modalId }) => {
    return axios.get(`/modals-mgr/${modalId}`, {
      headers: {
        auth: this.props.token,
      },
    });
  };

  componentDidMount() {
    if (this.state.isNewModal) {
      return;
    }

    this.fetchModal({ modalId: this.props.match.params.id })
      .then(resposne => {
        console.log(resposne);
        const mergedCtrlsWithRes = this.mergeResIntoCurrentModal({
          resModal: resposne.data,
        });

        const validatedCtrls = this.validateAllControls({
          controls: mergedCtrlsWithRes,
        });

        const allControlsValid = this.checkAllCtrlsValidity({
          controls: validatedCtrls,
        });

        this.setState({
          currentModalControls: validatedCtrls,
          allControlsValid: allControlsValid,
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  mergeResIntoCurrentModal = ({ resModal }) => {
    const nextCurrent = { ...this.state.currentModalControls };

    for (let key in resModal) {
      const nextValue = sanitizeInputValue({ value: resModal[key] });
      if (nextCurrent.hasOwnProperty(key)) {
        const prepopulatedOptions = this.prepopulateSelectedOptions({
          controlName: key,
          value: nextValue,
        });

        nextCurrent[key] = updateObject({
          oldObject: nextCurrent[key],
          updatedProperties: {
            value: nextValue,
            options: prepopulatedOptions,
          },
        });
      }
    }

    return nextCurrent;
  };

  validateAllControls = ({ controls }) => {
    const validatedControls = { ...controls };
    for (let singleControl in validatedControls) {
      validatedControls[singleControl] = updateObject({
        oldObject: validatedControls[singleControl],
        updatedProperties: {
          valid: this.checkValidity({
            value: validatedControls[singleControl].value,
            rules: validatedControls[singleControl].validation,
          }),
        },
      });
    }

    return validatedControls;
  };

  checkValidity = ({ value, rules }) => {
    let isValid = true;

    if (rules) {
      if (rules.required) {
        isValid = value.toString().trim() !== '' && isValid;
      }

      if (rules.isNumber) {
        if (value.toString().trim() !== '') {
          isValid = !isNaN(value) && isValid;
        }
      }

      if(rules.isPriority) {
        if (value.toString().trim() !== '') {
          isValid = !isNaN(value) && isValid && value > 0 && value < 100;
        }
      }

      if (rules.isCSSsize) {
        if (value.toString().trim() !== '') {
          const allowedUnits = ['px', '%'];

          const isValue = allowedUnits.some(unit => {
            const unitStart = value.indexOf(unit);
            const numToCheck = value.slice(0, unitStart);
            const unitToCheck = value.slice(unitStart, value.length);
            const isUnit = unitToCheck === unit;
            const isNumber = !isNaN(numToCheck);

            return isUnit && isNumber;
          });

          isValid = isValue && isValid;
        }
      }
    }
    return isValid;
  };

  prepopulateSelectedOptions = ({ controlName, value }) => {
    const control = { ...this.state.currentModalControls[controlName] };

    if (control.options) {
      return control.options.map(option => {
        const nextOption = { ...option };

        nextOption.checked = false;

        if (nextOption.value === value) {
          nextOption.checked = true;
        }
        return nextOption;
      });
    }
    return null;
  };

  checkAllCtrlsValidity = ({ controls }) => {
    const ctrlsToCheck = { ...controls };
    let allControlsValid = true;

    for (let oneControl in ctrlsToCheck) {
      allControlsValid = ctrlsToCheck[oneControl].valid && allControlsValid;
    }

    return allControlsValid;
  };

  inputChangedHandler = (inputName, value) => {
    // selects returns array of values, now we do't have multiselects
    const nextValue = sanitizeInputValue({ value });

    const control = this.state.currentModalControls[inputName];

    console.log(inputName, value);

    const updatedControlProperties = updateObject({
      oldObject: control,
      updatedProperties: {
        value: nextValue,
        touched: true,
        valid: this.checkValidity({
          value: nextValue,
          rules: control.validation,
        }),
      },
    });

    const updatedControl = { [inputName]: updatedControlProperties };

    const nextControls = updateObject({
      oldObject: this.state.currentModalControls,
      updatedProperties: updatedControl,
    });

    const nextCtrlsWithDependencies = this.toggleDependentCtrlsValRules({
      controls: nextControls,
      controlName: inputName,
    });

    const allControlsValid = this.checkAllCtrlsValidity({
      controls: nextCtrlsWithDependencies,
    });

    this.setState({
      currentModalControls: nextCtrlsWithDependencies,
      allControlsValid: allControlsValid,
    });
  };

  toggleDependentCtrlsValRules = ({ controls, controlName }) => {
    const updatedControls = { ...controls };

    if (updatedControls[controlName].hasOwnProperty('dependentControls')) {
      updatedControls[controlName].dependentControls.forEach(dependent => {
        const isActive =
          updatedControls[controlName].value === dependent.whenValue;

        const updatedProperties = updateObject({
          oldObject: updatedControls[dependent.name].validation,
          updatedProperties: { [dependent.validationRule]: isActive },
        });

        updatedControls[dependent.name] = updateObject({
          oldObject: updatedControls[dependent.name],
          updatedProperties: {
            validation: updatedProperties,
          },
        });

        updatedControls[dependent.name] = updateObject({
          oldObject: updatedControls[dependent.name],
          updatedProperties: {
            valid: this.checkValidity({
              value: updatedControls[dependent.name].value,
              rules: updatedControls[dependent.name].validation,
            }),
            marked: isActive,
          },
        });
      });
    }

    return updatedControls;
  };

  cancelEdition = () => {
    this.props.history.goBack();
  };

  saveModal = event => {
    event.preventDefault();
    const modalId = this.props.match.params.id;
    const modalToPOST = this.createModalToPOST();
    const isNew = this.state.isNewModal;

    const path = isNew ? '/modals-mgr' : `/modals-mgr/${modalId}`;

    axios
      .post(path, modalToPOST, {
        headers: {
          auth: this.props.token,
        },
      })
      .then(resposne => {
        this.context.updateList({
          id: resposne.data.id,
          name: modalToPOST.name,
          instance: modalToPOST.instance,
          type: modalToPOST.type,
          active: modalToPOST.active,
        });
        this.props.history.goBack();
      })
      .catch(error => console.log(error));
  };

  createModalToPOST = () => {
    const currentModal = { ...this.state.currentModalControls };
    const modalToPOST = {};

    for (let key in currentModal) {
      modalToPOST[key] = currentModal[key].value;
    }

    return modalToPOST;
  };

  togglePreview = ({ mode = 'desktop' }) => {
    this.setState(prevState => {
      return { isPreview: !prevState.isPreview, previewMode: mode };
    });
  };

  render() {
    return (
      <>
        <ModalPreview
          isShown={this.state.isPreview}
          mode={this.state.previewMode}
          isDisabled={!this.state.allControlsValid}
          toggleHandler={this.togglePreview}
          srcDesktop={this.state.currentModalControls.landingPageURL.value}
          srcMobile={this.state.currentModalControls.landingPageMobileURL.value}
          height={this.state.currentModalControls.height.value}
          heightMobile={this.state.currentModalControls.heightMobile.value}
          width={this.state.currentModalControls.width.value}
          widthMobile={this.state.currentModalControls.widthMobile.value}
          btnColor={this.state.currentModalControls.btnColor.value}
        />
        <ModalForm
          changeHandler={this.inputChangedHandler}
          controls={this.state.currentModalControls}
          isDisabled={!this.state.allControlsValid}
          save={this.saveModal}
          cancel={this.cancelEdition}
          isPopup={this.state.currentModalControls.isPopup.value}
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    instance: state.auth.instance
  };
};

export default connect(
  mapStateToProps,
  null
)(SingleModal);
