import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  MDBBtn,
  MDBCol,
  MDBRow,
  MDBBadge,
  MDBTable,
  MDBTableBody,
  MDBIcon,
} from 'mdbreact';

import TableHead from '../Table/TableHead/TableHead';
import Pagination from '../Paginantion/Pagination';

class ModalsList extends Component {
  componentDidMount() {
    this.props.getAllModals();
  }

  render() {
    let rows = this.props.modals.map(modal => {
      return { ...modal };
    });

    const rowsElements = rows.map(modal => {
      return {
        name: modal.name,
        instance: modal.instance,
        active: (
          <MDBBadge color={modal.active ? 'success' : 'light'}>
            {modal.active ? 'active' : 'inactive'}
          </MDBBadge>
        ),
        type: modal.type,
        createdAt: new Date(modal.createdAt * 1000).toLocaleString('pl-PL'),
        updatedAt: new Date(modal.updatedAt * 1000).toLocaleString('pl-PL'),
        actions: (
          <div>
            <MDBBtn
              color="default"
              size="sm"
              className="py-2"
              onClick={() => {
                this.props.editHandler({ modalId: modal.id });
              }}
            >
              <span className="sr-only">Edit</span>
              <MDBIcon icon="pen" />
            </MDBBtn>
            <MDBBtn
              color="danger"
              size="sm"
              className="py-2"
              onClick={() => {
                this.props.deleteHandler({ id: modal.id, name: modal.name });
              }}
            >
              <span className="sr-only">Delete</span>
              <MDBIcon icon="trash-alt" />
            </MDBBtn>
          </div>
        ),
      };
    });

    return (
      <>
        <MDBRow>
          <MDBCol>
            <MDBTable>
              <TableHead
                columns={this.props.columns}
                clickHandler={this.props.sortHandler}
              />
              <MDBTableBody rows={rowsElements} />
            </MDBTable>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol>
            <Pagination
              count={this.props.count}
              page={this.props.page}
              totalSize={this.props.totalSize}
              paginationHandler={this.props.paginationHandler}
            />
          </MDBCol>
        </MDBRow>
      </>
    );
  }
}

export default ModalsList;

ModalsList.propTypes = {
  columns: PropTypes.object,
  modals: PropTypes.arrayOf(PropTypes.object),
  count: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  totalSize: PropTypes.number.isRequired,
  paginationHandler: PropTypes.func.isRequired,
  getAllModals: PropTypes.func.isRequired,
  editHandler: PropTypes.func.isRequired,
  sortHandler: PropTypes.func.isRequired,
  deleteHandler: PropTypes.func.isRequired,
};
