import React from 'react';
import PropTypes from 'prop-types';
import { MDBRow, MDBCol, MDBBtn } from 'mdbreact';

import './ModalPreview.css';

const modalPreview = props => {
  const {
    isShown,
    isDisabled,
    mode,
    srcDesktop,
    srcMobile,
    height,
    heightMobile,
    width,
    widthMobile,
    toggleHandler,
    btnColor,
  } = props;

  const isDesktop = mode === 'desktop';

  let defHeight = isDesktop ? '500px' : '100%';
  let defWidth = isDesktop ? '768px' : '85%';

  const heightToRender = isDesktop
    ? height
      ? height
      : defHeight
    : heightMobile
    ? heightMobile
    : defHeight;

  const widthToRender = isDesktop
    ? width
      ? width
      : defWidth
    : widthMobile
    ? widthMobile
    : defWidth;

  const src = isDesktop ? srcDesktop : srcMobile;

  const wrapperStyle = {
    height: '100%',
    width: '100%',
  };

  if (!isDesktop) {
    wrapperStyle.height = '667px';
    wrapperStyle.width = '375px';
    wrapperStyle.backgroundColor = 'white';
  }

  const closeStyling = { backgroundColor: '#333' };

  if (btnColor) {
    closeStyling.backgroundColor = btnColor;
  }

  const iframe = (
    <div className="modal-preview">
      <MDBBtn
        className="modal-preview__close"
        onClick={event => {
          event.preventDefault();
          toggleHandler({ mode: 'desktop' });
        }}
        color="danger"
      >
        Close preview
      </MDBBtn>
      <div style={wrapperStyle}>
        <div className="modal-preview__dialog">
          <div
            style={{ height: heightToRender, width: widthToRender }}
            className="modal-preview__content"
          >
            <button
              onClick={event => {
                event.preventDefault();
              }}
              className="modal-preview__btn"
            >
              <span
                style={closeStyling}
                className="modal-preview__x modal-preview__x--1"
              />
              <span
                style={closeStyling}
                className="modal-preview__x modal-preview__x--2"
              />
              <span className="sr-only">close</span>
            </button>
            <iframe
              title="Preview"
              className="modal-preview__iframe"
              src={src}
            />
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <MDBRow>
      <MDBCol className="my-3">
        <MDBBtn
          onClick={event => {
            event.preventDefault();
            toggleHandler({ mode: 'desktop' });
          }}
          color="primary"
          disabled={isDisabled}
        >
          Desktop Preview
        </MDBBtn>
        <MDBBtn
          onClick={event => {
            event.preventDefault();
            toggleHandler({ mode: 'mobile' });
          }}
          color="primary"
          disabled={isDisabled}
        >
          Mobile Preview
        </MDBBtn>
      </MDBCol>
      {isShown ? iframe : null}
    </MDBRow>
  );
};

export default modalPreview;

modalPreview.propTypes = {
  isShown: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
  srcDesktop: PropTypes.string.isRequired,
  srcMobile: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  heightMobile: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  widthMobile: PropTypes.string.isRequired,
  btnColor: PropTypes.string.isRequired,
  toggleHandler: PropTypes.func.isRequired,
};
