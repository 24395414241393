import React from 'react';

import Input from './Input/Input';

const inputsGroup = props => {

  const formElementsArray = [];

  for (let key in props.controls) {
    if (props.group === props.controls[key].group)
      formElementsArray.push({ id: key, config: props.controls[key] });
  }

  const formElements = formElementsArray.map(element => {
    if(!props.isPopup && element.config.hide) {
      return null
    } else {
      return (
        <Input key={element.id} change={props.changeHandler} element={element} />
      );
    }
  });

  return (
    <fieldset>
      <legend>{props.title}</legend>
      {formElements}
    </fieldset>
  );
};

export default inputsGroup;
