import React from 'react';
import PropTypes from 'prop-types';
import { MDBIcon } from 'mdbreact';

import './TableHead.css';

const tableHead = props => {
  const { columns, clickHandler } = props;

  const columnsArray = [];

  for (let key in columns) {
    columnsArray.push({ id: key, ...columns[key] });
  }

  const heads = columnsArray.map(column => {
    const { id, label, isSortable, order } = column;
    const iconTypes = { asc: '-up', desc: '-down' };
    return (
      <th key={label} className="th-lg">
        {label}

        {isSortable ? (
          <MDBIcon
            onClick={() => clickHandler({ sortBy: id, prevOrder: order })}
            icon={`sort${iconTypes[order] || ''}`}
            className="ml-1 pointer"
          />
        ) : null}
      </th>
    );
  });

  return (
    <thead>
      <tr>{heads}</tr>
    </thead>
  );
};

export default tableHead;

tableHead.propTypes = {
  columns: PropTypes.objectOf(
    PropTypes.exact({
      label: PropTypes.string.isRequired,
      isSortable: PropTypes.bool.isRequired,
      order: PropTypes.oneOf(['', 'asc', 'desc']),
    })
  ),
  clickHandler: PropTypes.func,
};
