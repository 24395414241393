import React from 'react';
import PropTypes from 'prop-types';
import { MDBPagination, MDBPageItem, MDBPageNav } from 'mdbreact';

const pagination = props => {
  const { page, count, totalSize, paginationHandler } = props;

  const pagesCount = Math.ceil(totalSize / count);

  const arrowPrev =
    page - 1 > 0 ? (
      <MDBPageItem
        onClick={event => {
          event.preventDefault();
          paginationHandler(page - 1);
        }}
      >
        <MDBPageNav aria-label="Previous">
          <span aria-hidden="true">&laquo;</span>
          <span className="sr-only">Previous</span>
        </MDBPageNav>
      </MDBPageItem>
    ) : null;

  const prevPrev =
    page - 2 > 0 ? (
      <MDBPageItem
        onClick={event => {
          event.preventDefault();
          paginationHandler(page - 2);
        }}
      >
        <MDBPageNav>{page - 2}</MDBPageNav>
      </MDBPageItem>
    ) : null;

  const prev =
    page - 1 > 0 ? (
      <MDBPageItem
        onClick={event => {
          event.preventDefault();
          paginationHandler(page - 1);
        }}
      >
        <MDBPageNav>{page - 1}</MDBPageNav>
      </MDBPageItem>
    ) : null;

  const next =
    page + 1 <= pagesCount ? (
      <MDBPageItem
        onClick={event => {
          event.preventDefault();
          paginationHandler(page + 1);
        }}
      >
        <MDBPageNav>{page + 1}</MDBPageNav>
      </MDBPageItem>
    ) : null;

  const nextNext =
    page + 2 <= pagesCount ? (
      <MDBPageItem
        onClick={event => {
          event.preventDefault();
          paginationHandler(page + 2);
        }}
      >
        <MDBPageNav>{page + 2}</MDBPageNav>
      </MDBPageItem>
    ) : null;

  const arrowNext =
    pagesCount > page ? (
      <MDBPageItem
        onClick={event => {
          event.preventDefault();
          paginationHandler(page + 1);
        }}
      >
        <MDBPageNav aria-label="Next">
          <span aria-hidden="true">&raquo;</span>
          <span className="sr-only">Next</span>
        </MDBPageNav>
      </MDBPageItem>
    ) : null;

  return pagesCount > 1 ? (
    <MDBPagination>
      {arrowPrev}
      {prevPrev}
      {prev}
      <MDBPageItem active>
        <MDBPageNav>
          {page} <span className="sr-only">(current)</span>
        </MDBPageNav>
      </MDBPageItem>
      {next}
      {nextNext}
      {arrowNext}
    </MDBPagination>
  ) : null;
};

export default pagination;

pagination.propTypes = {
  count: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  totalSize: PropTypes.number.isRequired,
  paginationHandler: PropTypes.func.isRequired,
};
