export const updateObject = ({ oldObject, updatedProperties }) => {
  return { ...oldObject, ...updatedProperties };
};

export const sanitizeInputValue = ({ value }) => {
  if (typeof value === 'string') {
    return value.trim();
  }

  // MDB select returns an array, we dont'have multiselect, so 1st from an array is finr
  if (Array.isArray(value)) {
    //  path rules might be an array with many elements
    if (value.length > 1) {
      return value.join();
    }

    // sometimes MDB Select returns empty Array, when nothing selected, convert to ''
    return value[0] !== undefined ? value[0] : '';
  }

  return value;
};
