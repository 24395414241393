import * as actionTypes from '../actions/actionTypes';

import { updateObject } from '../../shared/utility';

const initialState = {
  token: null,
  instance: null,
  error: null,
  loading: false,
};

const authStart = (state, action) => {
  return updateObject({
    oldObject: state,
    updatedProperties: { error: null, loading: true },
  });
};

const authSuccess = (state, action) => {
  return updateObject({
    oldObject: state,
    updatedProperties: {
      token: action.token,
      instance: action.instance,
      error: null,
      loading: false,
    },
  });
};

const authLogout = (state, action) => {
  return updateObject({ oldObject: state, updatedProperties: { token: null, instance: null } });
};

const authFail = (state, action) => {
  return updateObject({
    oldObject: state,
    updatedProperties: {
      error: action.error,
      loading: false,
    },
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state, action);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state, action);
    case actionTypes.AUTH_FAIL:
      return authFail(state, action);
    default:
      return state;
  }
};

export default reducer;
