import React, { Component } from 'react';
import { MDBRow, MDBCol, MDBBtn } from 'mdbreact';

import InputsGroup from '../InputsGroup/InputsGroup';

import './ModalForm.css';

class ModalForm extends Component {
  render() {
    return (
      <form onSubmit={this.props.save} className="mx-3 grey-text">
        <MDBRow>
          <MDBCol>
            <InputsGroup
              group="general"
              title="General"
              changeHandler={this.props.changeHandler}
              controls={this.props.controls}
              isPopup={this.props.isPopup}
            />
          </MDBCol>
          <MDBCol>
            <InputsGroup
              group="content"
              title="Content"
              changeHandler={this.props.changeHandler}
              controls={this.props.controls}
              isPopup={this.props.isPopup}
            />
          </MDBCol>
          <MDBCol>
            <InputsGroup
              group="displayRules"
              title="Display rules"
              changeHandler={this.props.changeHandler}
              controls={this.props.controls}
              isPopup={this.props.isPopup}
            />
          </MDBCol>
          <MDBCol>
            <InputsGroup
              group="audience"
              title="Audience"
              changeHandler={this.props.changeHandler}
              controls={this.props.controls}
              isPopup={this.props.isPopup}
            />
          </MDBCol>
        </MDBRow>
        <MDBBtn onClick={this.props.cancel} color="danger">
          Cancel
        </MDBBtn>
        <MDBBtn
          type="submit"
          color={this.props.isDisabled ? 'blue-grey' : 'success'}
          disabled={this.props.isDisabled}
        >
          Save
        </MDBBtn>
      </form>
    );
  }
}

export default ModalForm;
