import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  MDBBtn,
  MDBCol,
  MDBRow,
  MDBInput,
  MDBCard,
  MDBCardBody,
  MDBSpinner,
} from 'mdbreact';

import * as actions from '../../store/actions/index';

class Auth extends Component {
  state = {
    company: '',
    username: '',
    password: '',
  };

  inputChangedHandler = inputName => value => {
    const nextValue = value;
    this.setState({ [inputName]: nextValue });
  };

  loginHandler = event => {
    event.preventDefault();

    this.props.onAuth({
      company: this.state.company,
      username: this.state.username,
      password: this.state.password,
    });
  };


  render() {
    return (
      <MDBRow
        center
        style={{ height: '100%' }}
        className="align-content-center"
      >
        <MDBCol md="6">
          <MDBCard>
            <MDBCardBody>
              <form>
                <p className="h4 text-center py-4">Sign in</p>
                <div className="grey-text">
                  <MDBInput
                    label="Company (case-sensitive)"
                    icon="building"
                    group
                    type="text"
                    validate
                    getValue={this.inputChangedHandler('company')}
                    className={this.props.error ? "is-invalid" : ""}
                  />
                  <MDBInput
                    label="Your login"
                    icon="user"
                    group
                    type="text"
                    validate
                    error="wrong"
                    success="right"
                    getValue={this.inputChangedHandler('username')}
                    className={this.props.error ? "is-invalid" : ""}
                  />
                  <MDBInput
                    label="Your password"
                    icon="lock"
                    group
                    type="password"
                    validate
                    getValue={this.inputChangedHandler('password')}
                    className={this.props.error ? "is-invalid" : ""}
                  >
                    {this.props.error && <div className="invalid-feedback">{this.props.error.data.message}</div>}
                  </MDBInput>
                </div>
                <div className="text-center py-4 mt-3">
                  {!this.props.loading ? (
                    <MDBBtn
                      color="cyan"
                      type="submit"
                      onClick={this.loginHandler}
                    >
                      Login
                    </MDBBtn>
                  ) : (
                    <MDBSpinner />
                  )}
                </div>
              </form>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.auth.loading,
    error: state.auth.error
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onAuth: ({ company, username, password }) =>
      dispatch(actions.auth({ company, username, password })),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Auth);
