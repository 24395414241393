import React from 'react';
import { MDBRow, MDBCol, MDBInput, MDBSelect } from 'mdbreact';

import './Input.css';

const input = props => {
  let inputelement = null;
  const elementType = props.element.config.type || 'input';
  const extraClassNames = props.element.config.classNames || 'col-md-12';

  const inputClasses = [];
  const isValid = props.element.config.valid;
  const isTouched = props.element.config.touched;
  const validationClass = isValid ? 'is-valid' : 'is-invalid';

  const markedClass = props.element.config.marked ? 'is-marked' : '';

  isTouched
    ? inputClasses.push(validationClass)
    : inputClasses.push(markedClass);

  const feedback = props.element.config.feedback
    ? props.element.config.feedback
    : '';

  const invalidFeedback =
    !isValid && isTouched ? (
      <div
        style={{ display: 'block', marginTop: '-0.2rem' }}
        className="invalid-feedback"
      >
        {feedback}
      </div>
    ) : null;

  switch (elementType) {
    case 'input': {
      inputelement = (
        <>
          <MDBInput
            label={props.element.config.label}
            value={props.element.config.value}
            className={inputClasses.join(' ')}
            getValue={value => {
              props.change(props.element.id, value);
            }}
          />
          {invalidFeedback}
        </>
      );
      break;
    }

    case 'select': {
      inputelement = (
        <div>
          <MDBSelect
            selected="Choose..."
            label={props.element.config.label}
            options={props.element.config.options}
            className={inputClasses.join(' ')}
            getValue={value => {
              props.change(props.element.id, value);
            }}
          />
        </div>
      );
      break;
    }

    case 'textarea': {
      inputelement = (
        <MDBInput
          type="textarea"
          rows="2"
          label={props.element.config.label}
          value={props.element.config.value}
          className={inputClasses.join(' ')}
          getValue={value => {
            props.change(props.element.id, value);
          }}
        />
      );
      break;
    }

    default:
      inputelement = null;
  }

  return (
    <MDBRow>
      <MDBCol className={extraClassNames}>{inputelement}</MDBCol>
    </MDBRow>
  );
};

export default input;
