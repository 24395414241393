import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBDatePicker,
  MDBInput,
  MDBSelect,
  MDBIcon,
} from 'mdbreact';

import ModalsList from '../../components/ModalsList/ModalsList';

import TableEntries from '../../components/Table/TableEntries/TableEntries';

class ModalsManager extends Component {
  state = {
    statusOptions: [
      {
        text: 'any',
        value: '',
        checked: true,
      },
      {
        text: 'active',
        value: true,
      },
      {
        text: 'inactive',
        value: false,
      },
    ],
    typeOptions: [
      {
        text: 'any',
        value: '',
        checked: true,
      },
      {
        text: 'onStart',
        value: 'onStart',
      },
      {
        text: 'onExit',
        value: 'onExit',
      },
      {
        text: 'onScroll',
        value: 'onScroll',
      },
    ],
    isPopupOptions: [
      {
        text: 'Yes',
        value: true,
      },
      {
        text: 'No',
        value: false,
      },
    ],
  };

  componentDidMount() {
    this.prepopulateOptions({
      name: 'statusOptions',
      value: this.props.searchControls.instance,
    });

    this.prepopulateOptions({
      name: 'typeOptions',
      value: this.props.searchControls.type,
    });
  }

  prepopulateOptions = ({ name, value }) => {
    const options = [...this.state[name]];

    const nextOptions = options.map(option => {
      const updatedOption = { ...option };

      updatedOption.checked = value === option.value;

      return updatedOption;
    });

    this.setState({ [name]: nextOptions });
  };

  decodeDate = date => {
    if (date === '') {
      return null;
    }

    return new Date(date * 1000);
  };

  encodeDate = ({ date, isEndDay = false }) => {
    if (date === null) {
      return '';
    }

    const updatedDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );

    if (isEndDay) {
      updatedDate.setHours(23, 59, 59);
    }

    return (updatedDate.getTime() / 1000) | 0;
  };

  editHandler = ({ modalId }) => {
    this.props.history.push(`/modals/${modalId}`);
  };

  render() {
    return (
      <>
        <MDBRow>
          <MDBCol lg="3">
            <MDBInput
              label={'Name'}
              value={this.props.searchControls.name}
              getValue={value => {
                this.props.change({
                  inputName: 'name',
                  value,
                });
              }}
            />
          </MDBCol>
          <MDBCol lg="3">
            <MDBInput
              label={'Website'}
              value={this.props.searchControls.instance}
              getValue={value => {
                this.props.change({ inputName: 'instance', value });
              }}
            />
          </MDBCol>
          <MDBCol lg="2">
            <div>
              <MDBSelect
                label="Type"
                options={this.state.typeOptions}
                getValue={value => {
                  this.props.change({ inputName: 'type', value });
                }}
              />
            </div>
          </MDBCol>
        </MDBRow>
        <MDBRow className="mb-5">
          <MDBCol lg="3">
            <div className="md-form" style={{ marginTop: '1.3rem' }}>
              <MDBDatePicker
                clearable={true}
                disableFuture={true}
                format="DD/MM/YYYY"
                valueDefault={null}
                getValue={value => {
                  this.props.change({
                    inputName: 'createdAtFrom',
                    value: this.encodeDate({ date: value }),
                  });
                }}
              />
              <label
                className="active text-primary"
                style={{
                  top: '-5px',
                }}
              >
                Creation Date from
              </label>
            </div>
          </MDBCol>
          <MDBCol lg="3">
            <div className="md-form" style={{ marginTop: '1.3rem' }}>
              <MDBDatePicker
                clearable={true}
                disableFuture={true}
                format="DD/MM/YYYY"
                valueDefault={null}
                getValue={value => {
                  this.props.change({
                    inputName: 'createdAtTo',
                    value: this.encodeDate({ date: value, isEndDay: true }),
                  });
                }}
              />
              <label
                className="active text-primary"
                style={{
                  top: '-5px',
                }}
              >
                Creation Date to
              </label>
            </div>
          </MDBCol>
          <MDBCol lg="2">
            <div>
              <MDBSelect
                label="Status"
                options={this.state.statusOptions}
                getValue={value => {
                  this.props.change({ inputName: 'active', value });
                }}
              />
            </div>
          </MDBCol>
          <MDBCol lg="2">
            <div>
              <MDBSelect
                label="isPopup"
                options={this.state.isPopupOptions}
                getValue={value => {
                  this.props.change({ inputName: 'isPopup', value });
                }}
              />
            </div>
          </MDBCol>
          <MDBCol className="d-flex justify-content-end">
            <MDBBtn
              onClick={() => {
                this.props.history.push(`/modals/new`);
              }}
              size="lg"
              color="primary"
            >
              Add <MDBIcon icon="plus" className="ml-1" />
            </MDBBtn>
          </MDBCol>
        </MDBRow>
        <MDBRow className="justify-content-end">
          <MDBCol lg="2">
            <TableEntries
              count={this.props.count}
              entriesHandler={this.props.entriesHandler}
            />
          </MDBCol>
        </MDBRow>
        <ModalsList
          editHandler={this.editHandler}
          deleteHandler={this.props.deleteHandler}
          getAllModals={this.props.getAllModals}
          modals={this.props.modals}
          sortHandler={this.props.sortHandler}
          columns={this.props.columns}
          sort={this.props.sort}
          order={this.props.order}
          searchControls={this.props.searchControls}
          count={this.props.count}
          page={this.props.page}
          totalSize={this.props.totalSize}
          paginationHandler={this.props.paginationHandler}
        />
      </>
    );
  }
}

export default ModalsManager;

ModalsManager.propTypes = {
  getAllModals: PropTypes.func.isRequired,
  deleteHandler: PropTypes.func.isRequired,
};
