import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MDBSelect } from 'mdbreact';

class TableEntries extends Component {
  state = {
    options: [
      {
        text: '10',
        value: 10,
      },
      {
        text: '25',
        value: 25,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '100',
        value: 100,
      },
    ],
  };

  componentDidMount() {
    this.prepopulate();
  }

  prepopulate = () => {
    const options = [...this.state.options];

    const nextOptions = options.map(option => {
      const updatedOption = { ...option };

      updatedOption.checked = this.props.count === option.value;

      return updatedOption;
    });

    this.setState({ options: nextOptions });
  };

  render() {
    return (
      <div>
        <MDBSelect
          label="Show entries"
          options={this.state.options}
          getValue={this.props.entriesHandler}
        />
      </div>
    );
  }
}

export default TableEntries;

TableEntries.propTypes = {
  entriesHandler: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
};
